import { LitElement, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { Ayetu } from '@ayetu/sdk-js';

type NetType = 'Main' | 'Test';

@customElement('ayetu-init')
export class AyetuInitComponent extends LitElement {
  @property({ type: String }) net: NetType = 'Test';
  @property({ type: Boolean }) debug = false;

  connectedCallback() {
    super.connectedCallback();
    console.log('AyetuInitComponent connected');

    if (!['Main', 'Test'].includes(this.net)) {
      console.error(`Invalid net value: ${this.net}. Defaulting to "Test".`);
      this.net = 'Test';
    }

    const urlParams = new URLSearchParams(window.location.search);
    const connectionToken = urlParams.get('connection-token');
    const updateToken = urlParams.get('update-token');

    if (connectionToken) {
      urlParams.delete('connection-token');
    }
    if (updateToken) {
      urlParams.delete('update-token');
    }

    let newUrl = window.location.pathname;
    const paramsString = urlParams.toString();
    if (paramsString) {
      newUrl += `?${paramsString}`;
    }

    window.history.replaceState(null, '', newUrl);

    // Register event listeners
    console.log('Registering event listeners');
    Ayetu.eventEmitter.on('auth-status-changed', this.handleAuthStatusChange);
    Ayetu.eventEmitter.on('error', this.handleError);
    Ayetu.eventEmitter.on('session-token', this.handleSessionToken);

    console.log('Initializing with:', {
      net: this.net,
      connectionToken: connectionToken || undefined,
      updateToken: updateToken || undefined,
      url: window.location.href,
      debug: this.debug,
    });

    Ayetu.init({
      net: this.net,
      connectionToken: connectionToken || undefined,
      updateToken: updateToken || undefined,
      url: window.location.href,
      debug: this.debug,
    });
  }

  disconnectedCallback() {
    console.log('AyetuInitComponent disconnected');

    // Cleanup event listeners
    console.log('Unregistering event listeners');
    Ayetu.eventEmitter.off('auth-status-changed', this.handleAuthStatusChange);
    Ayetu.eventEmitter.off('error', this.handleError);
    Ayetu.eventEmitter.off('session-token', this.handleSessionToken);

    super.disconnectedCallback();
  }

  handleAuthStatusChange = (data: { status: string }) => {
    console.log('Auth status changed (event emitter):', data.status);
    alert(`Auth status changed: ${data.status}`);
  };

  handleError = (data: { message: string }) => {
    console.error('Error occurred:', data.message);
    alert(`Error: ${data.message}`);
  };

  handleSessionToken = (data: { sessionToken: string }) => {
    console.log('Session token:', data.sessionToken);
    alert(`Session token: ${data.sessionToken}`);
  };

  render() {
    return html`<p>Ayetu SDK initialized with net: ${this.net}, debug: ${this.debug}.</p>`;
  }
}
